import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    uid: '',
    admin: null,
    signInDone: false
  },
  getters: {
    isAuthenticated: state => {
      return state.uid !== ''
    }
  },
  mutations: {
    setUId (state, uid) {
      state.uid = uid
    },
    setAdmin (state, admin) {
      state.admin = admin
    },
    setSignInDone (state, signInDone) {
      state.signInDone = signInDone
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
