import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'sign-in',
    component: () => import(/* webpackChunkName: "sign-in" */ '../views/SignIn.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
  },
  {
    path: '/qrCodes',
    name: 'qrCodes',
    component: () => import(/* webpackChunkName: "qrCodes" */ '../views/QRCodes.vue')
  },
  {
    path: '/invalidAdmin',
    name: 'invalid-admin',
    component: () => import(/* webpackChunkName: "invalid-admin" */ '../views/InvalidAdmin.vue')
  },
  {
    path: '/signOut',
    name: 'sign-out',
    component: () => import(/* webpackChunkName: "sign-out" */ '../views/SignOut.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const { name: toName } = to
  // const { name: fromName } = from

  if (store.getters.isAuthenticated) {
    next()
  } else {
    switch (toName) {
      case 'home':
      case 'reports':
      case 'sign-out':
        next({ replace: true, name: 'sign-in' })
        break
      case 'invalid-admin':
        if (store.state.signInDone) {
          next()
        } else {
          next({ replace: true, name: 'sign-in' })
        }
        break
      case 'sign-in':
        next()
    }
  }
})

export default router
